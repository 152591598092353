import React from "react";
import { useNavigate } from "react-router-dom";
import OpportunityIcon from "../../../assets/opportunity.png";
import PeopleImg from "../../../assets/PeopleImg.png";
import axios from "../../../api/axios.js";
import useAuth from "../../../hooks/useAuth.js";
import "./index.css";

const Opportunity = (props) => {
  const { auth } = useAuth();
  const navigate = useNavigate();
  

  const getOppInfo = async () => {
    try {
      navigate("/opportunitydetails", {
        state: {
          title: props.name,
          loc: props.location,
          desc: props.info,
        },
      });
    } catch {}
  };
  return (
    <div className="oppWrapper">
      <div className="oppInfo">
        {/* <div className="oppFirmImgCon">
          <img className="oppFirmImg" src={props.firmlogo} alt="/" />
        </div> */}
        <div className="oppDetails1">
          <div className="oppHeading">{props.name}</div>
          <div className="oppFirm">
            <span className="oppDetailSpan">Location : </span>
            {props.location}
          </div>
          <div className="oppFirm">
            <span className="oppDetailSpan">Eligiblity : </span>
            {props.eligiblity}
          </div>
        </div>
      </div>
      <div className="oppDetails2">
        <div className="oppFirm">
          <span className="oppDetailSpan">Description : </span>
          {props.info}
        </div>
      </div>
      <div className="oppStatus">
        <div className="oppSingleDetailLeft">
          <div className="oppIconWrapper">
            <img className="oppIcon" src={OpportunityIcon} alt="/" />
          </div>
          <div className="oppInformation">{props.typeofopportunity}</div>
        </div>
        <div className="oppSingleDetailCenter">
          <div className="oppIconWrapperPeople">
            <img className="oppIconPeople" src={PeopleImg} alt="/" />
          </div>
          <div className="oppInformationPeople">56 people applied</div>
        </div>
        <div className="oppSingleDetailRight">
          <button
            onClick={getOppInfo}
            className="yellowBtn"
            style={{ width: "80%" }}
          >
            Know More
          </button>
        </div>
      </div>
    </div>
  );
};

export default Opportunity;
