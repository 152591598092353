import React, { useState, useEffect } from "react";
import Navbar from "../Navbar";
import axios from "../../api/axios";
import { useNavigate } from "react-router-dom";
import "./AllBlogs.css";

const Blogs = () => {
  const [blogs, setBlogs] = useState([]);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);
  const navigate = useNavigate();

  useEffect(() => {
    fetchBlogs();
  }, []);

  const fetchBlogs = async () => {
    try {
      const response = await axios.get("/api/blogs/getBlogs");
      setBlogs(response.data);
    } catch (err) {
      setError(err.response?.data?.message || "Failed to fetch blogs");
    } finally {
      setLoading(false);
    }
  };

  const handleBlogClick = (slug) => {
    navigate(`/blog/${slug}`);
  };

  return (
    <div className="homeWrapper">
      <div className="homeNavbar">
        <Navbar></Navbar>
      </div>
      <div className="blogsWrapper">
        {/* Blogs array from backend should get displayed here in form of cards */}
        {loading ? (
          <div className="blogs-loading">Loading blogs...</div>
        ) : error ? (
          <div className="blogs-error">{error}</div>
        ) : blogs.length === 0 ? (
          <div className="blogs-empty">No blogs found</div>
        ) : (
          <div className="blogs-grid">
            {blogs.map((blog) => (
              <div
                key={blog.id}
                className="blog-card"
                onClick={() => handleBlogClick(blog.slug)}
              >
                <div className="blog-card-image">
                  <img
                    src={`${process.env.REACT_APP_BASE_URL}${blog.placeholderImageUrl}`}
                    alt={blog.title}
                  />
                </div>
                <div className="blog-card-content">
                  <h2 className="blog-card-title">{blog.title}</h2>
                  <p className="blog-card-introduction">
                    {blog.introduction.length > 150
                      ? `${blog.introduction.substring(0, 150)}...`
                      : blog.introduction}
                  </p>
                  <div className="blog-card-footer">
                    <span className="blog-card-date">
                      {new Date(blog.createdAt).toLocaleDateString("en-US", {
                        year: "numeric",
                        month: "long",
                        day: "numeric",
                      })}
                    </span>
                    <span className="blog-card-read-more">Read More →</span>
                  </div>
                </div>
              </div>
            ))}
          </div>
        )}
      </div>
    </div>
  );
};

export default Blogs;
