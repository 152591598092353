import React, { useState, useEffect } from "react";
import { useLocation } from "react-router-dom";
import Sidebar from "../../Usables/Sidebar";
import Header from "../../Headers/HeaderProfile";
import Mentors from "./Mentors";
import "./index.css";
import OpportunityDetail from "./OpportunityDetail";
import { TailSpin } from "react-loader-spinner";
import axios from "../../../api/axios.js";
const OpportunityDetails = () => {
  // MENTORS VAALI PROCESSING YAHA START HONI CHAHIYE, IF HE CAME HERE IT MEANS HE CLCIKED KNOW MORE ON PREVIOUS PAGE
  // Threfore move the mentor fun here
  // fetchMentors();, yaha par terko loader lagana hai mentors ko load krana hai
  const location = useLocation();
  const title = location.state?.title || "";
  const loc = location.state?.loc || "";
  const desc = location.state?.desc || "";

  const fetchMentors = async () => {
    try {
      const response = await axios.post(
        "https://opgururag.azurewebsites.net/search",
        {
          query: `Find me three mentors for an opportunity whose title is ${title}, description is ${desc} and the opportunity is in ${loc}`,
        }
      );
      console.log(
        "Response data after OPGURURAG Mentor API is =>",
        response.data
      );
      const finalData = response.data;
      setMentorArr(finalData);
      console.log("finalData is ", finalData);
    } catch (error) {
      console.error(
        "Error fetching Mentors from OPGURURAG Mentor API :",
        error
      );
    }
  };

  const [mentorArr, setMentorArr] = useState([]);
  // This array would have been saved in Mongo DB by RAG in background till now
  const [loading, setLoading] = useState(true);

  // useEffect(() => {
  //   const callApi = async () => {
  //     try {
  //       const response = await axios.get("/api/getMentors", {
  //         params: {
  //           userID :
  //         },
  //       });
  //       if (response.status !== 200) {
  //         throw new Error("Mentors not found yet");
  //       }
  //       const mentors = await response.data;
  //       console.log("data from saved opps is ", mentors);
  //       setMentorArr(JSON.parse(mentors.mentorList));
  //       setLoading(false);
  //       clearInterval(interval);
  //     } catch (err) {
  //       console.log("Error is ", err);
  //     }
  //     return () => {
  //       clearInterval(interval);
  //     };
  //   };
  //   let interval = setInterval(() => callApi(), 5000);
  // }, []);

  return (
    <div className="opportunityWrapper">
      <div className="opportunityHeader">
        <Header></Header>
      </div>
      <div className="opportunityBottom">
        <div className="opportunitySidebar">
          <Sidebar></Sidebar>
        </div>
        <div className="opportunityDetailsRight">
          {loading ? (
            <div className="loaderWrapper">
              <h1>Loading opportunity information and related mentors</h1>
              <TailSpin
                visible={true}
                height="80"
                width="80"
                color="#dfa114"
                ariaLabel="tail-spin-loading"
                radius="2"
                wrapperStyle={{}}
                wrapperClass=""
              />
            </div>
          ) : (
            <div className="opportunityInformation">
              {" "}
              <div className="oppDetails">
                <OpportunityDetail></OpportunityDetail>
              </div>
              <div className="mentors">
                <Mentors mentorArr={mentorArr}></Mentors>
              </div>
            </div>
          )}
        </div>
      </div>
    </div>
  );
};

export default OpportunityDetails;
