import React, { useState } from "react";
import axios from "../../api/axios";
import "./BlogEditor.css";

const BlogEditor = () => {
  const [formData, setFormData] = useState({
    title: "",
    introduction: "",
    placeholderImage: null,
    placeholderImagePreview: null,
    leftImage: null,
    leftImagePreview: null,
    rightImage: null,
    rightImagePreview: null,
    rightContent: "",
    leftContent: "",
    conclusion: "",
  });

  const handleImageChange = (e, imageType) => {
    const file = e.target.files[0];
    if (file) {
      setFormData((prev) => ({
        ...prev,
        [imageType]: file,
        [`${imageType}Preview`]: URL.createObjectURL(file),
      }));
    }
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    const formDataToSend = new FormData();

    // Append text fields
    formDataToSend.append("title", formData.title);
    formDataToSend.append("introduction", formData.introduction);
    formDataToSend.append("rightContent", formData.rightContent);
    formDataToSend.append("leftContent", formData.leftContent);
    formDataToSend.append("conclusion", formData.conclusion);

    // Append files only if they exist
    if (formData.placeholderImage) {
      formDataToSend.append("placeholderImage", formData.placeholderImage);
    }
    if (formData.leftImage) {
      formDataToSend.append("leftImage", formData.leftImage);
    }
    if (formData.rightImage) {
      formDataToSend.append("rightImage", formData.rightImage);
    }

    try {
      const response = await axios.post("/api/blogs/addblog", formDataToSend, {
        headers: {
          "Content-Type": "multipart/form-data",
        },
      });
      console.log("response is ", response);
      if (response.status === 201) {
        alert("Blog posted successfully!");
      } else {
        throw new Error("Failed to post blog");
      }
    } catch (error) {
      alert("Error posting blog: " + error.message);
    }
  };

  return (
    <div className="blog-container">
      {/* Editor Section */}
      <div className="blog-editor-section">
        <h2 className="blog-section-title">Create New Blog</h2>
        <form onSubmit={handleSubmit} className="blog-form">
          <div className="blog-form-fields">
            <div className="blog-form-group">
              <label className="blog-label">Title</label>
              <input
                className="blog-input"
                value={formData.title}
                onChange={(e) =>
                  setFormData((prev) => ({ ...prev, title: e.target.value }))
                }
                placeholder="Enter blog title"
              />
            </div>

            <div className="blog-form-group">
              <label className="blog-label">Introduction (2-3 lines)</label>
              <textarea
                className="blog-textarea"
                value={formData.introduction}
                onChange={(e) =>
                  setFormData((prev) => ({
                    ...prev,
                    introduction: e.target.value,
                  }))
                }
                placeholder="Write a brief introduction"
                rows={3}
              />
            </div>

            <div className="blog-form-group">
              <label className="blog-label">Placeholder Image</label>
              <input
                type="file"
                className="blog-file-input"
                accept="image/*"
                onChange={(e) => handleImageChange(e, "placeholderImage")}
              />
              {formData.placeholderImagePreview && (
                <div className="blog-image-preview-container">
                  <img
                    src={formData.placeholderImagePreview}
                    alt="Placeholder preview"
                    className="blog-image-preview"
                  />
                </div>
              )}
            </div>

            <div className="blog-form-row">
              <div className="blog-form-group">
                <label className="blog-label">Left Image</label>
                <input
                  type="file"
                  className="blog-file-input"
                  accept="image/*"
                  onChange={(e) => handleImageChange(e, "leftImage")}
                />
              </div>

              <div className="blog-form-group">
                <label className="blog-label">Content Next to Left Image</label>
                <textarea
                  className="blog-textarea"
                  value={formData.rightContent}
                  onChange={(e) =>
                    setFormData((prev) => ({
                      ...prev,
                      rightContent: e.target.value,
                    }))
                  }
                  placeholder="Content to appear right of the first image"
                  rows={4}
                />
              </div>
            </div>

            <div className="blog-form-row">
              <div className="blog-form-group">
                <label className="blog-label">
                  Content Next to Right Image
                </label>
                <textarea
                  className="blog-textarea"
                  value={formData.leftContent}
                  onChange={(e) =>
                    setFormData((prev) => ({
                      ...prev,
                      leftContent: e.target.value,
                    }))
                  }
                  placeholder="Content to appear left of the second image"
                  rows={4}
                />
              </div>

              <div className="blog-form-group">
                <label className="blog-label">Right Image</label>
                <input
                  type="file"
                  className="blog-file-input"
                  accept="image/*"
                  onChange={(e) => handleImageChange(e, "rightImage")}
                />
              </div>
            </div>

            <div className="blog-form-group">
              <label className="blog-label">Conclusion (5-10 lines)</label>
              <textarea
                className="blog-textarea"
                value={formData.conclusion}
                onChange={(e) =>
                  setFormData((prev) => ({
                    ...prev,
                    conclusion: e.target.value,
                  }))
                }
                placeholder="Write your conclusion"
                rows={6}
              />
            </div>
          </div>

          <button type="submit" className="blog-submit-button">
            Publish Blog
          </button>
        </form>
      </div>

      {/* Preview Section */}
      <div className="blog-preview-section">
        <h2 className="blog-section-title">Preview</h2>
        <div className="blog-preview-container">
          <div className="blog-preview-header">
            <h1 className="blog-preview-title">
              {formData.title || "Your Title Here"}
            </h1>
            <div className="blog-separator"></div>
            {formData.placeholderImagePreview && (
              <div className="blog-placeholder-image-wrapper">
                <img
                  src={formData.placeholderImagePreview}
                  alt="Placeholder"
                  className="blog-preview-image"
                />
              </div>
            )}
            <p className="blog-preview-introduction">
              {formData.introduction || "Your introduction will appear here..."}
            </p>
          </div>

          <div className="blog-content-section">
            <div className="blog-image-text-row">
              <div className="blog-image-wrapper">
                {formData.leftImagePreview ? (
                  <img
                    src={formData.leftImagePreview}
                    alt="Left content"
                    className="blog-preview-image"
                  />
                ) : (
                  <div className="blog-image-placeholder">First Image</div>
                )}
              </div>
              <div className="blog-text-content">
                <p>
                  {formData.rightContent ||
                    "Content next to left image will appear here..."}
                </p>
              </div>
            </div>

            <div className="blog-image-text-row">
              <div className="blog-text-content">
                <p>
                  {formData.leftContent ||
                    "Content next to right image will appear here..."}
                </p>
              </div>
              <div className="blog-image-wrapper">
                {formData.rightImagePreview ? (
                  <img
                    src={formData.rightImagePreview}
                    alt="Right content"
                    className="blog-preview-image"
                  />
                ) : (
                  <div className="blog-image-placeholder">Second Image</div>
                )}
              </div>
            </div>

            <div className="blog-conclusion-section">
              <p>
                {formData.conclusion || "Your conclusion will appear here..."}
              </p>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default BlogEditor;
