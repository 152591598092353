import React, { useState } from "react";
import "./index.css";
import OpGuruRoutes from "./OpGuruRoutes.js";
import { useNavigate } from "react-router-dom";
import useAuth from "../../hooks/useAuth.js";
import useLogout from "../../hooks/useLogout.js";
import MobileNav from "./mobile-nav.js";
import OpguruLogo from "../../assets/opgurulogo.png";

const Navbar = () => {
  const { auth } = useAuth();
  const logout = useLogout();

  const signOut = async () => {
    await logout();
    navigate("/");
  };
  const navigate = useNavigate();
  return (
    <div className="navWrapper">
      <div className="brandGroup">
        <MobileNav />
        <div className="navLogo" onClick={() => navigate("/")}>
          <img className="opguruLogoImg" src={OpguruLogo} alt="/" />
        </div>
      </div>
      <div className="navLinks">
        {OpGuruRoutes.map((route, key) => {
          return (
            <div
              className={
                window.location.pathname === "/" + route.linkTo
                  ? "navLink activeLink"
                  : "navLink"
              }
              onClick={() => navigate(`/${route.linkTo}`)}
            >
              {route.name}
            </div>
          );
        })}
      </div>

      {auth?.name ? (
        <div className="navButtons">
          <button className="navButton" onClick={() => navigate("/profile")}>
            {auth.name}
          </button>
          <button className="navButton" onClick={signOut}>
            Logout
          </button>
        </div>
      ) : (
        <div className="navButtons">
          <button
            className="navButton"
            id="loginButton"
            onClick={() => navigate("/login")}
          >
            Login
          </button>
          <button className="navButton" onClick={() => navigate("/register")}>
            Sign Up
          </button>
        </div>
      )}
    </div>
  );
};

export default Navbar;
