import React, { useState, useEffect } from "react";
import { useParams } from "react-router-dom";
import axios from "../../api/axios";
import "./BlogDetail.css";

const BlogDetail = () => {
  const { slug } = useParams();
  const [blog, setBlog] = useState(null);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);

  useEffect(() => {
    const fetchBlog = async () => {
      try {
        const response = await axios.get(`/api/blogs/${slug}`);
        setBlog(response.data);
      } catch (err) {
        setError(err.response?.data?.message || "Failed to fetch blog");
      } finally {
        setLoading(false);
      }
    };

    fetchBlog();
  }, [slug]);

  useEffect(() => {
    console.log("blog is ", blog);
  }, [blog]);

  if (loading) {
    return <div className="blog-detail-loading">Loading...</div>;
  }

  if (error) {
    return <div className="blog-detail-error">{error}</div>;
  }

  return (
    <article className="blog-detail-container">
      {/* Rest of the JSX remains the same as before */}
      <div className="blog-detail-content">
        <header className="blog-detail-header">
          <h1 className="blog-detail-title">{blog.title}</h1>
          <div className="blog-detail-meta">
            <span className="blog-detail-date">
              {new Date(blog.createdAt).toLocaleDateString("en-US", {
                year: "numeric",
                month: "long",
                day: "numeric",
              })}
            </span>
          </div>
          <div className="blog-detail-separator"></div>
          <p className="blog-detail-introduction">{blog.introduction}</p>
        </header>

        <div className="blog-detail-main">
          <div className="blog-detail-section">
            <div className="blog-detail-image-text-row">
              <div className="blog-detail-image">
                <img
                  src={`${process.env.REACT_APP_BASE_URL}${blog.leftImageUrl}`}
                  alt="Blog content"
                  className="blog-detail-img"
                />
              </div>
              <div className="blog-detail-text">
                <p>{blog.rightContent}</p>
              </div>
            </div>
          </div>

          <div className="blog-detail-section">
            <div className="blog-detail-image-text-row reverse">
              <div className="blog-detail-text">
                <p>{blog.leftContent}</p>
              </div>
              <div className="blog-detail-image">
                <img
                  src={`${process.env.REACT_APP_BASE_URL}${blog.rightImageUrl}`}
                  alt="Blog content"
                  className="blog-detail-img"
                />
              </div>
            </div>
          </div>

          <div className="blog-detail-conclusion">
            <p>{blog.conclusion}</p>
          </div>
        </div>
      </div>
    </article>
  );
};

export default BlogDetail;
